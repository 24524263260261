var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_general")) + " ")]),
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: {
            model: _vm.store.form,
            rules: _vm.formRules,
            "label-align": "left",
            "wrapper-col": { span: 12 },
            "label-col": { span: 7 }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_branch"),
                        prop: "branchWarehouseId"
                      }
                    },
                    [
                      _c("SelectBranch", {
                        attrs: { name: "work-order_branch" },
                        model: {
                          value: _vm.store.form.branchWarehouseId,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "branchWarehouseId", $$v)
                          },
                          expression: "store.form.branchWarehouseId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_wo_date"),
                        prop: "workOrderDate"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          name: "work-order_wo-date"
                        },
                        model: {
                          value: _vm.store.form.workOrderDate,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "workOrderDate", $$v)
                          },
                          expression: "store.form.workOrderDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_schedule_order"),
                        prop: "scheduleOrder"
                      }
                    },
                    [
                      _c("a-date-picker", {
                        attrs: {
                          format: _vm.DEFAULT_DATE_FORMAT,
                          "disabled-date": _vm.useDisabledBackDate,
                          name: "work-order_schedule-order"
                        },
                        model: {
                          value: _vm.store.form.scheduleOrder,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "scheduleOrder", $$v)
                          },
                          expression: "store.form.scheduleOrder"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_mechanic_name"),
                        prop: "mechanicId"
                      }
                    },
                    [
                      _c("SelectMechanic", {
                        attrs: { name: "work-order_mechanic" },
                        model: {
                          value: _vm.store.form.mechanicId,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "mechanicId", $$v)
                          },
                          expression: "store.form.mechanicId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_description"),
                        prop: "description"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "extra",
                          fn: function() {
                            return [
                              _c("character-length", {
                                attrs: { value: _vm.store.form.description }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("a-textarea", {
                        attrs: { name: "work-order_description" },
                        model: {
                          value: _vm.store.form.description,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "description", $$v)
                          },
                          expression: "store.form.description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_unit_code"),
                        prop: "unitCode",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("SelectUnit", {
                        attrs: { name: "work-order_unit-code" },
                        on: { "update:meta": _vm.onChangeUnitCode },
                        model: {
                          value: _vm.store.form.unitCode,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "unitCode", $$v)
                          },
                          expression: "store.form.unitCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_customer_name"),
                        prop: "customer",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("SelectCustomer", {
                        attrs: {
                          name: "work-order_customer-name",
                          "label-in-value": ""
                        },
                        on: { "update:meta": _vm.onChangeCustomer },
                        model: {
                          value: _vm.store.form.customer,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "customer", $$v)
                          },
                          expression: "store.form.customer"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_customer_address"),
                        prop: "customerAddress",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-textarea", {
                        attrs: {
                          name: "work-order_customer-address",
                          disabled: ""
                        },
                        model: {
                          value: _vm.store.form.customerAddress,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "customerAddress", $$v)
                          },
                          expression: "store.form.customerAddress"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_customer_pic_name"),
                        prop: "picName",
                        "label-align": "right"
                      }
                    },
                    [
                      _c("a-input", {
                        attrs: {
                          name: "work-order_customer-pic-name",
                          "allow-clear": ""
                        },
                        model: {
                          value: _vm.store.form.picName,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "picName", $$v)
                          },
                          expression: "store.form.picName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }